import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import menuLinks from '../../assets/menu_links.json'

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener('mousedown', (e) => {
      // user click toogle
      if (toggle_ref.current && toggle_ref.current.contains(e.target)){
          content_ref.current.classList.toggle('active')
      } else {
          // user click outside toggle and content
          if (content_ref.current && !content_ref.current.contains(e.target)){
              content_ref.current.classList.remove('active')
          }
      }
  })
}

function Navbar() {
  const menu_ref = useRef(null);
    const menu_toggle_ref = useRef(null);

    clickOutsideRef(menu_ref, menu_toggle_ref);

    const setActiveMenu = () => menu_ref.current.classList.add('active');
    const closeMenu = () => menu_ref.current.classList.remove('active');

  return (
    <div className='navbar'>
      <Link to='/' className='navbar_companyLogo'>
        <img src='https://res.cloudinary.com/emacon-production/image/upload/v1652117632/Vienna%20Technologies%20LTD/WhatsApp_Image_2022-05-09_at_7.17.04_PM_domiza.jpg' alt='Vienna Technologies LTD' />
      </Link>

      <div className='navbar_menuLinkWrapper'>
        {
          menuLinks &&
          menuLinks.map((item, index) => (
            <Link key={index} to={item.route} className='navbar_menuLinks' >
              {item.placeholder}
            </Link>
          ))
        }
      </div>

      {/* Mobile Menu */}
      <div className='mobileMenu_btn' ref={menu_toggle_ref} onClick={() => setActiveMenu()} >
          <i class='bx bx-menu-alt-right'></i>
      </div>

      <div className='mobileMenu_content' ref={menu_ref}>
        <div className='mobileMenu_closebtn' onClick={() => closeMenu()}>
            <i className="bx bx-x"></i>
        </div>

        <div className='mobileMenu_linksWrapper'>
          {
            menuLinks &&
            menuLinks.map((item, index) => (
              <Link key={index} to={item.route} className='mobileMenu_inks' >
                {item.placeholder}
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Navbar