import React from 'react'
import { Link } from 'react-router-dom'
import './serviceCard.css'

function ServiceCard({ data }) {
  return (
    <div className='serviceCard'>
        <div className="imgBx">
            <img src={data.thumbnail} />
        </div>
        
        <div className='contentBx'>
            <p>{data.service}</p>

            <Link to={`/service?type=${data.routeType}`} className='learnBtn_link' >
                {`Learn more `}
                <i class='bx bx-right-top-arrow-circle'></i>
            </Link>
{/* 
            <div className="learnBtn">
                <Link to="/" className='learnBtn_link' >
                    {`Learn more `}
                    <i class='bx bx-right-top-arrow-circle'></i>
                </Link>
            </div> */}
        </div>
        
    </div>
  )
}

export default ServiceCard