import React from 'react'
import Slider from 'react-slick/lib/slider';
import './partners.css'

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false
  };


const images =[
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417764/Vienna%20Technologies%20LTD/Tononoka_befsfl.png",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417764/Vienna%20Technologies%20LTD/OLA_yowoeh.jpg",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417764/Vienna%20Technologies%20LTD/KSL_ndi4s3.png",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417764/Vienna%20Technologies%20LTD/KEMU_iszhr8.jpg",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417764/Vienna%20Technologies%20LTD/JAVA_icelbf.png",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417764/Vienna%20Technologies%20LTD/KFC_cowqdw.png",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417764/Vienna%20Technologies%20LTD/Jubilee_lyqz9y.jpg",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417765/Vienna%20Technologies%20LTD/Consolata_ymr0ly.jpg",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417765/Vienna%20Technologies%20LTD/Coffee_roasters_k7jfeh.jpg",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417765/Vienna%20Technologies%20LTD/Crystal_crbphv.png",
    "https://res.cloudinary.com/emacon-production/image/upload/v1652417765/Vienna%20Technologies%20LTD/Care_eohylt.jpg"
]

function Partners() {
  return (
    <div className='partners_wrapper'> 
        <Slider {...settings} >
            {
                images.map((image, index) => (
                    <img src={image} alt="Our Partners" key={index} className="partners" />
                ))
            }
        </Slider>
    </div>
    
  )
}

export default Partners