import React from 'react';
import './whatsApp.css'

function WhatsApp() {
  return (
    <a href="https://wa.me/254713582420" class="whatsapp_float" target="_blank"> 
        <i class="fa fa-whatsapp whatsapp-icon"></i>
    </a>
  )
}

export default WhatsApp