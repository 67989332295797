export const services = [
    {
        thumbnail: "https://res.cloudinary.com/emacon-production/image/upload/v1651469766/Vienna%20Technologies%20LTD/CF028060_hsg9yt.jpg",
        service: "Generator sales, installations\ synchronization and overhauling. Generator maintenance, services and repairs. ",
        routeType: "generators"
    },
    {
        thumbnail: "https://res.cloudinary.com/emacon-production/image/upload/v1651469767/Vienna%20Technologies%20LTD/_DSC7447_m1hrjv.jpg",
        service: "Part Sales",
        routeType: "parts"
    },
    {
        thumbnail: "https://res.cloudinary.com/emacon-production/image/upload/v1652771231/Vienna%20Technologies%20LTD/Screenshot_from_2022-05-17_10-06-45_oqynvi.png",
        service: "Supply and Installation of Solar Systems; both Water heating and power backup",
        routeType: "solar_systems"
    },
    {
        thumbnail: "https://res.cloudinary.com/emacon-production/image/upload/v1651469766/Vienna%20Technologies%20LTD/CF028073_zpfu9h.jpg",
        service: "Electrical works, Power Distribution, Cables Lying, Maintenance Contract",
        routeType: "electrical-works"
    }
]

export const maintenancePrograms = [
    {
        title: "Annual Maintenance",
        thumbnail: "https://res.cloudinary.com/emacon-production/image/upload/v1652082729/Vienna%20Technologies%20LTD/undraw_Maintenance_re_59vn_zlismk.png",
        description: "Whether you own a large or small commercial generator for your business, your system will require regular maintenance to sustain its reliability & performance. Our standard, three times-a-year program will keep your emergency generator system online and ready for any power outage or emergency."
    },
    {
        title: "Custom Maintenance",
        thumbnail: "https://res.cloudinary.com/emacon-production/image/upload/v1652082729/Vienna%20Technologies%20LTD/undraw_product_tour_re_8bai_aydiyx.png",
        description: "We can help you get the best performance, reliability & long life from your system with one of our comprehensive, custom service programs to fit the specific power demands of your business. If you rely on your system for critical needs, we can customize a program to meet your specific requirements."
    },
    {
        title: "Emergency Service 24 hours/day - 7 days/week - 365 days/year",
        thumbnail: "https://res.cloudinary.com/emacon-production/image/upload/v1652082729/Vienna%20Technologies%20LTD/undraw_Time_management_re_tk5w_jmwksm.png",
        description: "If your generator fails at any time - day or night, during any time of the year - you can always depend on us. Our team of factory trained Engineers/technicians provide, prompt response to get you back on line as soon as possible. And, we have fully stocked vans/vehicles ready to roll from our conveniently located offices in Nairobi."
    },
    {
        title: "Technical Support",
        thumbnail: "https://res.cloudinary.com/emacon-production/image/upload/v1652082729/Vienna%20Technologies%20LTD/undraw_QA_engineers_dg5p_ixwfd8.png",
        description: "With every Generator service call, you receive the unmatched support of our dedicated field team and administrative support personnel. All our Generator professionals are ready to provide complete technical support whenever you need it. Our systems expertise, extensive field experience, on-going factory training and customer satisfaction have made us serve our customers diligently."
    }
]

export const responsibilities = [
    {
        title: "Work Force",
        description: "The Company boasts of highly experienced group of professionals. Our team consists of skilled and talented services engineers, technicians, laborers and various other working staffs. They do not hesitate to work an extra mile in their effort to delight the customers."
    },
    {
        title: "Quality Control",
        description: "Our products and services are rendered to our valued client with an assurance of the best deal. We religiously stick to the quality control policy incorporated by the management of the company. Both our products as well as services are governed only by quality."
    },
    {
        title: "Customer Satisfaction",
        description: "Our customer centric approach plays a vital role in meeting the specific demands of the clients with precision. With the aim of achieving maximum customer satisfaction, we have started following sophisticated delivery mechanism that ensures timely delivery of products in flawless condition. Customized packaging solutions are also available for delivering products to the clients place in proper condition within the stipulated time frame. We ensure to render services that can satisfy customers to the fullest and keep their generators sets trouble free for a longer period of time."
    }
]