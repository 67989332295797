import React, { useEffect } from 'react'
import './home.css'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navigation_bar/Navbar'
import Partners from '../../components/partners/Partners'
import WhatsApp from '../../components/whatsApp_button/WhatsApp'
import { Col, Row } from 'react-bootstrap'
import { maintenancePrograms, responsibilities, services } from '../../assets/Helper'
import ServiceCard from '../../components/service_cards/ServiceCard'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick/lib/slider'
import Chat from '../../components/Chat/Chat'

const settings = {
  infinite: true,
  arrows: true,
  speed: 0.5,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseonhover: false,
  cssEase: "linear"
};

const carouselImages = [
  'carousel__background1',
  'carousel__background2',
  'carousel__background3',
  'carousel__background4'
]

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    <div className='home'>
      <Navbar />

      <div className='home_content'>
        <div className='home_hero'>
          <Slider {...settings} className="carousel" >
            {
              carouselImages.map((item, index) => (
                  <div className={`${item}`} key={index}>
                      {/* <img src={item} alt="" /> */}
                      <div className='black_wrapper' >
                        <Row className='home_hero_content' >
                          <Col lg={7} md={9} xs={12}>
                            <h1>WORLD OF ENGINEERING TECHNOLOGY</h1>
                            <p>Your daily operations depend on a reliable source of power.</p>
                            <button onClick={() => navigate("/contactus")}>Request Quote</button>
                          </Col>
                        </Row>
                      </div>
                  </div>
              ))
            }
          </Slider>
          {/* <Row className='home_hero_content' >
            <Col lg={7} md={9} xs={12}>
              <h1>WORLD OF ENGINEERING TECHNOLOGY</h1>
              <p>Your daily operations depend on a reliable source of power.</p>
              <button onClick={() => navigate("/contactus")}>Request Quote</button>
            </Col>
          </Row> */}
        </div>

        <div className='home_contentData' >
          <div className='home_contentData_title'>
            <i class='bx bx-chevrons-right'></i>
            <h2>Our Services</h2>
          </div>
          <Row>
            {
              services.map((item, index) => (
                <Col lg={3} md={6} xs={12} key={index} >
                  <ServiceCard data={item} />
                </Col>
              ))
            }
            
          </Row>
        </div>

        <div className='home_contentData' >
          <div className='home_contentData_title'>
            <i class='bx bx-chevrons-right'></i>
            <h2> Consistent Power Supply</h2>
          </div>
          
          <p>
            Your daily operations depend on a reliable source of power. That's the reason you have a backup generator - to keep your workplace, customers, or municipal
            services up & running during any unexpected loss of electricity. Proper care and maintenance are critical to ensure that your generator is always
            ready to run and operates at peak performance.
          </p>
          <Row>
            {
              maintenancePrograms.map((item, index) => (
                <Col lg={3} md={6} xs={12} key={index} >
                  <div className="maintenancePrograms_thumbnail" >
                    <img src={item.thumbnail} alt={`Viena Power Solutions LTD - ${item.title}`} />
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </Col>
              ))
            }
          </Row>
        </div>

        <div className=''><div className='home_contentData_banner' >
          <div className='home_contentData_banner_content'>
            <h2>Satisfaction & Feedback</h2>
            <p>
              At VIENA POWER SOLUTIONS, we are constantly working to improve our quality of service to our customers and want to hear feedback from our stake holders.
              In order to maintain our continuing high standards we issue a customer satisfaction report on all completed projects that our customers may complete and return. 
              This way we constantly monitor the quality of workmanship through to the quality of management. Below are some other ways of how we ensure customer satisfaction:
            </p>
            <button onClick={() => navigate("/contactus")} >Reach Out Today</button>
          </div>
          
        </div>

        </div>

        <div className='home_contentData' >
        <div className='home_contentData_title'>
            <i class='bx bx-chevrons-right'></i>
            <h2>Always Responsible </h2>
          </div>
          
          <Row>
            {
              responsibilities.map((item, index) => (
                <Col lg={4} md={4} xs={12} key={index} >
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </Col>
              ))
            }
          </Row>
        </div>

        <Partners />
        
      </div>

      <Footer />

      <WhatsApp />
{/* 
      <Chat /> */}

    </div>
  )
}

export default Home