import React, { useEffect } from 'react'
import queryString from 'query-string'
import Navbar from '../../components/navigation_bar/Navbar';
import Footer from '../../components/footer/Footer';
import { Col, Row } from 'react-bootstrap';
import './service.css'
import { useNavigate } from 'react-router-dom';
import WhatsApp from '../../components/whatsApp_button/WhatsApp';

function Service() {
    const navigate = useNavigate();

    const { type } = queryString.parse(window.location.search);

    useEffect(() => {
        window.scrollTo(0,0);
        
        if(!type) {
            navigate('/product&services')
        }
    }, [])

  return (
    <div className='service'>
        <Navbar />
        
        <div className='service_content'>
            <Row>
                {
                    type === "generators" ? (
                        <React.Fragment>
                            <Col lg={4} md={4} xs={12}>
                                <img src='https://res.cloudinary.com/emacon-production/image/upload/v1651469766/Vienna%20Technologies%20LTD/CF028060_hsg9yt.jpg' alt="Vinna Technologies Ltd - Generators, AMC services, Operation, Maintenance and overhauling" />
                            </Col>
                            <Col lg={8} md={8} xs={12}>
                                <h3>Generators, AMC services, Operation, Maintenance and overhauling</h3>
                                <p>
                                    Performing scheduled maintenance and testing combined with repairing issues that can cause
                                    failure, ensure there is emergency power when required. Maintenance steps are performed on
                                    generator on a calendar schedule or accumulated hours of operation. Most commonly divided into
                                    quarterly. semi-annual and annual checks. Each generator manufacturer recommends inspections
                                    and tasks to be performed during these scheduled checks. All generator maintenance is conducted
                                    in accordance with manufacturer specifications. <br/>
                                    Maintenance will often identify upcoming problems that could cause a generator to shut down.
                                    Conditions such as worn belts, damaged hoses and low fluid levels can cause an emergency power
                                    failure because of generator engine shutdown. We pay close attention to detail when inspecting
                                    generator sets. Often clients return for creation of a service contract. To see some of the projects
                                    we have completed go to project profiles. <br/>
                                    Testing the generator is often identified as an included check in maintenance programs. Starting the
                                    generator but not running under load conditions can be part of an industrial generator requirements.
                                    While load testing the generator is used in facilities with critical power needs. Load testing places a
                                    varying load on the generator for a predetermined number of hours. Maintenance combined with
                                    load testing adds an additional layer of protection to the generator.
                                    When a generator fails any test, does not operate to standards or shuts down during operation, it
                                    requires troubleshooting steps to identify the cause of the problem. Generators consist of two
                                    independent systems communicating with one another to deliver needed power. Engine is the prime
                                    mover for the generator. Engine Electronic Control Module(s) (ECM) monitor and control engine
                                    functions. The generator controller monitors the alternator and communicates with the ECM to
                                    accommodate loads. <br/>
                                    Our skilled technicians can view electronic alarm messages and determine the faulty component
                                    within the failed system. Repairs are completed to manufacturers specifications. The generator is
                                    tested and returned to Automatic mode of operation. Sometimes building equipment such as an
                                    Automatic Transfer Switch (ATS) or failed electrical distribution equipment can cause an emergency
                                    power failure. We can troubleshoot and repair any failed emergency, standby, prime or continuous
                                    power system.
                                </p>
                                {/* <ul>
                                    <li>We are dealers/distributors of Yor Power Generators specialized in Cummins, Perkins, Volvo Penta and MTU Generators.</li>
                                    <li>We also deal with SDMO, Caterpillar and Deutz generators.</li>
                                    <li>We offer maintenance and after market support to all types of generators.</li>
                                    <li>Industrial and domestic generators- preventive and maintenance services.</li>
                                    <li>AMC (annual maintenance contract) services with and without spares and consumables.</li>
                                    <li>Supply, Installation, commissioning, load testing of generators andoperation.</li>
                                    <li>Supply of ATS and AMF panels, installation and commissioning.</li>
                                </ul> */}
                            </Col>
                        </React.Fragment>
                    ) : <></> &&
                    type === "parts" ? (
                        <React.Fragment>
                            <Col lg={4} md={4} xs={12}>
                                <img src='https://res.cloudinary.com/emacon-production/image/upload/v1651469767/Vienna%20Technologies%20LTD/_DSC7447_m1hrjv.jpg' alt="Vinna Technologies Ltd - Transformers, Repairing, Maintenance (Dehydration and Testing)" />
                            </Col>
                            <Col lg={8} md={8} xs={12}>
                                <h3>Part Sales</h3>
                                <ul>
                                    <li>To offer excellent services to our customers we stock genuine parts. both engine and electrical.</li>
                                    <li>We also stock consumables like filters, coolants and lubricants to support the AMC business</li>
                                </ul>
                            </Col>
                        </React.Fragment>
                    ) : <></> &&
                    type === "solar_systems" ? (
                        <React.Fragment>
                            <Col lg={4} md={4} xs={12}>
                                <img src='https://res.cloudinary.com/emacon-production/image/upload/v1658221030/Vienna%20Technologies%20LTD/chelsea-WvusC5M-TM8-unsplash_u6on5x.jpg' alt="Vinna Technologies Ltd - Air Conditioning, Installation, Services, Repairing, Compressors Suppling" />
                            </Col>
                            <Col lg={8} md={8} xs={12}>
                                <h3>Supply and Installation of Solar Systems</h3>
                                <p>
                                    Viena Power Solutions goal is to provide homes and businesses right across this country with a
                                    renewable energy solutions &amp; quality solar energy systems. We are proud to say we save our valued
                                    customers money in energy bills while working towards a cleaner environment. Our clients are happier
                                    and are more power efficient. <br />
                                    You may be a single site business or multinational company, come from the private or public sector, or
                                    be based in the heart of Nairobi or the most remote rural location like Turkana. Whether your objective
                                    is to reduce your carbon footprint, drive down energy costs or secure a reliable energy supply, we will
                                    work with you to find your ideal renewable energy solution.
                                </p>

                                <h4>Benefits that we offer:</h4>
                                <ul>
                                    <li>
                                        <h5>SAVE ENVIRONMENT</h5>
                                        <span>- Solar panels generate free electricity from the sun for use around your home, helping to combat greenhouse gas emissions</span>
                                    </li>
                                    <li>
                                        <h5>SAVE ON BILLS</h5>
                                        <span>- After initial purchase and installation, you can begin to generate free electricity and your panels will pay for themselves in a few years.</span>
                                    </li>
                                    <li>
                                        <h5>STORE ENERGY</h5>
                                        <span>- Choose to use the energy around your home or sell it back to Kenya Power for a competitive feed-in tariff.</span>
                                    </li>
                                    <li>
                                        <h5>GOVERNMENT SUBSIDY</h5>
                                        <span>- Solar Panels are subsidised by the Kenyan government.</span>
                                    </li>
                                </ul>

                                <h4>What are the types of solar panels?</h4>
                                <p>There are 3 main types of solar panels, Monocrystalline, Polycrystalline and thinfilm(amorphous) solar panels.</p>
                                <ul>
                                    <li>Monocrystalline solar panels are the most efficient type with efficiency of as high as 25% followed by the polycrystalline type.</li>
                                    <li>Perovskite solar panels are a new type that have shown high efficiency of up to 45% in recent months with rapid increases in conversion efficiency but have yet proven to be commercially viable.</li>
                                </ul>
                            </Col>
                        </React.Fragment>
                    ) : <></> &&
                    type === "electrical-works" ? (
                        <React.Fragment>
                            <Col lg={4} md={4} xs={12}>
                                <img src='https://res.cloudinary.com/emacon-production/image/upload/v1651469766/Vienna%20Technologies%20LTD/CF028073_zpfu9h.jpg' alt="Vinna Technologies Ltd - Electrical works, Power Distribution, Cables Lying, Maintenance Contract" />
                            </Col>
                            <Col lg={8} md={8} xs={12}>
                                <h3>Electrical works, Power Distribution, Cables Lying, Maintenance Contract</h3>
                                <ul>
                                    <li>New distribution panels, star delta/ DOL panels, soft starter motor panels.</li>
                                    <li>Supplier of all electrical accessories and equipment.</li>
                                    <li>Cable laying, cable dressing, swapping and cable termination of all rating LT/HT.</li>
                                    <li>AMC (annual maintenance contract) services provider for office and building power maintenance.</li>
                                    <li>Office and building wiring and lighting.</li>
                                    <li>Emergency lighting.</li>
                                    <li>Earth and grounding system complete job including testing.</li>
                                    <li>Surge protection system.</li>
                                    <li>Imported UPS , installation, commissioning and operation.</li>
                                    <li>UPS imported and local for domestic use.</li>
                                    <li>Solar system for domestic and commercial use (all rating).</li>
                                </ul>
                            </Col>
                        </React.Fragment>
                    ) : <></>
                }
            </Row>
        </div>

        <Footer />

        <WhatsApp />
    </div>
  )
}

export default Service