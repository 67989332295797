import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { services } from '../../assets/Helper'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navigation_bar/Navbar'
import Partners from '../../components/partners/Partners'
import ServiceCard from '../../components/service_cards/ServiceCard'
import WhatsApp from '../../components/whatsApp_button/WhatsApp'
import './about.css'

function About() {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <div className='about'>
        <Navbar />

        <div className='about_content'>
            <div className='about_content_section1'>
              <div className='about_section1_bg'></div>
              <div className='about_section1_title' >
                <h1>ABOUT US</h1>
              </div>
            </div>

            <div className='about_content_section2'>
              <p>
                Viena Power Solutions Limited was established in Kenya to provide: Generator Supply, installation, commissioning, maintenance and
                troubleshooting, parts sales and overhauling service to all brands of gensets’, Electrical installations for both single and three phase systems,
                Electrical Building services, Refrigeration and air conditioning, Power factor capacitor banks, Voltage regulators/stabilizers,
                Power distribution panels, Office wiring, lighting, earth and grounding. <br/>

                Our commitment is to provide the very best service to ensure the highest quality output for our clients remains our standard. <br/>

                This can only be achieved with good communication and understanding, and the correct use of contract management tools: Reporting, progress evaluation, 
                and an imaginative flexible approach to solving problems. <br/>

                Viena Power Solutions Ltd is a private limited company offering contracting services, particularly in the public and private sectors globally.
              </p>

              <Row style={{ marginTop: "2rem"}}>
                <Col lg={6} md={6} xs={12}>
                  <h3>Our Mission</h3>
                  <p>
                    To provide a level of service that will exceed the expectations of our clients,
                    maintain the highest level of quality and allow our employees the opportunity to
                    grow and prosper. To instill confidence with all parties by professionally managing
                    all aspects of each and every project be it large or small. At Viena Power Solutions
                    we:
                    <ul>
                      <li>Aim to supply a high quality and versatile service to all clients, delivering the best value at all times whilst continuing to develop our people skills through advance training opportunities. </li>
                      <li>Make continuous improvements to our services and people.</li>
                      <li>Operate an open and honest relationship with all of our clients and this includes joint decision making processes, agreed objectives and anticipated goals.</li>
                    </ul>
                  </p>
                </Col>

                <Col lg={6} md={6} xs={12}>
                  <h3>Values and Vision</h3>
                  <p>
                    We act with integrity in all of our interactions never comparing our
                    business ethics. This is the cornerstone of who we are as a company, and
                    is essential to our future success. The company has clear values, which
                    are:
                    <ul>
                      <li>Safety- we care about people.</li>
                      <li>Reliability – we have integrity</li>
                      <li>Responsiveness – we listen to what you say.Viena Power Solutions Ltd has a proven track record of effectively delivering diverse projects to a broad range of customers.</li>
                    </ul>
                    We are:
                    <ul>
                      <li>Dedicated - proactive and dependable</li>
                      <li>Agile – flexible and smart</li>
                      <li>Measured- we make carefully balanced decisions</li>
                    </ul>
                  </p>
                </Col>
              </Row>

              {/* <Row>
                {
                  services.map((item, index) => (
                    <Col lg={3} key={index} >
                      <ServiceCard data={item} />
                    </Col>
                  ))
                }
                
              </Row> */}
            </div>
            <Partners />
        </div> 

        <Footer />
        <WhatsApp />
    </div>
  )
}

export default About