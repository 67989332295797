import React, { useEffect, useRef, useState } from 'react'
import './contactUs.css'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navigation_bar/Navbar'
import { useForm } from 'react-hook-form'
import { Col, Row, Spinner } from 'react-bootstrap'
import WhatsApp from '../../components/whatsApp_button/WhatsApp'
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const renderLoadingButton = () => (
  <div style={{ display: "flex", justifyContent: "center" }}>
      <Spinner animation="border" variant="light" />
  </div>
)

function ContactUs() {
  const form = useRef();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [ loading, setLoading ] = useState(false);

  const onSubmit = (data) => {
    // e.preventDefault();

    setLoading(true);

    console.log(data)

    emailjs.sendForm("service_ri74tii", "template_21e83zt", form.current, "o7CLrF-7gkB8t_Ach")
      .then(res => {
        console.log(res.text);
        toast(`${res.text}. Message sent successfully`, {
          type: "success",
          position: "top-right",
          autoClose: "1000",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
        setLoading(false);
      })
      .catch(err => {
        console.log(err.text);
        toast(`Oops! ${err.text}`, {
          type: "error",
          position: "top-right",
          autoClose: "1000",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
        setLoading(false);
      })
    console.log(data)
  };

  console.log(watch("name")); // watch input value by passing the name of it

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    <div className='contact_us'>
        <Navbar />

        <div className='contact_us_content'>
          <div className='contact_us_content_section1'>
            <h2>Contact Viena Power Solutions.</h2>
            <p>If you wish to contact Viena Power Solutions, please fill out the form below.</p>
          </div>

          <Row>
            <Col lg={7} md={7} xs={12}>
              <form ref={form} onSubmit={handleSubmit(onSubmit)} >
                {/* register your input into the hook by invoking the "register" function */}
                <div className='input_labels'>
                  <label for="name">Name*</label>
                  {errors.name && <span>This field is required</span>}
                </div>
                <input id='name' defaultValue="" { ...register("name", { required: true }) } />

                <div className='input_labels'>
                  <label for="email">Email Address*</label>
                  {errors.email && <span>This field is required</span>}
                </div>
                <input id='email' defaultValue="" { ...register("email", { required: true }) } />

                <div className='input_labels'>
                  <label for="phone">Phone Number*</label>
                  {errors.phone && <span>This field is required</span>}
                </div>
                <input id='phone' defaultValue="" { ...register("phone", { required: true }) } />

                <div className='input_labels'>
                  <label for="subject">Subject*</label>
                  {errors.subject && <span>This field is required</span>}
                </div>
                <input id='subject' defaultValue="" { ...register("subject", { required: true }) } />

                <div className='input_labels'>
                  <label for="message">Message*</label>
                  {errors.message && <span>This field is required</span>}
                </div>
                <textarea id="message" name="message" rows="4" cols="50" defaultValue=""  { ...register("message", { required: true }) } />

                <button type='submit'>{loading ? renderLoadingButton() : "Submit"}</button>
              </form>
            </Col>

            <Col lg={5} md={5} xs={12} className="company_details_wrapper" >
              <h3>Our Contact Details</h3>
              <div className='company_details'>
                <h5>Company Name:</h5>
                <p>VIENA POWER SOLUTIONS LIMITED</p>
              </div>
              <div className='company_details'>
                <h5>Office:</h5>
                <p>Lusaka Close-off, Lusaka Road</p>
              </div>
              <div className='company_details'>
                <h5>Address:</h5>
                <p>P.O BOX 59940-00200, NAIROBI, KENYA</p>
              </div>
              <div className='company_details'>
                <h5>Contact:</h5>
                <p>+254 768 468707</p>
              </div>
              <div className='company_details'>
                <h5>Email:</h5>
                <p>info@vienapowersolutionsltd.com</p>
              </div>
            </Col>
          </Row>
          
        </div>

        <Footer />

        <WhatsApp />

        <ToastContainer />
    </div>
  )
}

export default ContactUs