import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About';
import ProductServices from './pages/product_services/ProductServices';
import ContactUs from './pages/contact_us/ContactUs';
import Service from './pages/service/Service';
import { useEffect } from 'react';

function App() {
  
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/product&services' element={<ProductServices />} />
      <Route path='/service' element={<Service />} />
      <Route path='/contactus' element={<ContactUs />} />
    </Routes>
  );
}

export default App;
