import React from 'react'
import './footer.css'
import menuLinks from '../../assets/menu_links.json'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer' >
      <div className='footer_section1'>
        <div className='footer_menu'>
          {
            menuLinks &&
            menuLinks.map((item, index) => (
              <Link key={index} to={item.route} className='footer_links' >
                {item.placeholder}
              </Link>
            ))
          }
        </div>

        <div className='footer_socials'>
          <a href='' target='_blank' className='footer_links'>
            <i class='bx bxl-facebook' ></i>
          </a>

          <a href='' target='_blank' className='footer_links'>
            <i class='bx bxl-linkedin' ></i>
          </a>
        </div>
      </div>

      <div className='footer_section2'></div>

      <div className='footer_section3'>
        <p>
          {`Copyright ©  `}
          <a href='' className='footer_copyrightlinks' >Viena Power Solutions</a>
          {` ${new Date().getFullYear()} `}
          {/* <a href='https://victor-githui.netlify.app/' className='footer_copyrightlinks' >Victor Githui</a> */}
        </p>
      </div>
    </div>
  )
}

export default Footer