import React, { useEffect } from 'react'
import './productsServices.css'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navigation_bar/Navbar'
import { Col, Row } from 'react-bootstrap'
import { services } from '../../assets/Helper'
import ServiceCard from '../../components/service_cards/ServiceCard'
import WhatsApp from '../../components/whatsApp_button/WhatsApp'

function ProductServices() {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <div className='productServices'>
        <Navbar />

        <div className='productServices_content'>
          <div className='productServices_content_section1'>
            <div className='productServices_section1_bg'></div>
            <div className='productServices_section1_title' >
              <h1>PRODUCT & SERVICES</h1>
            </div>
          </div>

          <div className='productServices_content_section2'>
            <Row>
              {
                services.map((item, index) => (
                  <Col lg={3} md={4} xs={12} key={index} >
                    <ServiceCard data={item} />
                  </Col>
                ))
              }
              
            </Row>
          </div>
          
        </div>

        <Footer />

        <WhatsApp />
    </div>
  )
}

export default ProductServices